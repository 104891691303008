select:disabled {

color: #050505;
    -webkit-box-shadow: inset 5em 5em rgb(171, 232, 247) !important;
    -moz-box-shadow: inset 5em 5em rgb(171, 232, 247) !important;
    box-shadow: inset 5em 5em rgb(171, 232, 247) !important;

}

input:disabled {

    color: #050505;
        -webkit-box-shadow: inset 5em 5em rgb(171, 232, 247) !important;
        -moz-box-shadow: inset 5em 5em rgb(171, 232, 247) !important;
        box-shadow: inset 5em 5em rgb(171, 232, 247) !important;
    
    }


    select:focus {
        color: #050505;
        -webkit-box-shadow: inset 5em 5em rgb(166, 255, 225) !important;
        -moz-box-shadow: inset 5em 5em rgb(166, 255, 225) !important;
        box-shadow: inset 5em 5em rgb(166, 255, 225) !important;
    
    }