body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .FondoLogin {
  background-repeat: no-repeat; 
  background-position: center center;
  background-image: url("./img/FondoLogin.jpg");
}


.main2-sidebar2 a:-moz-focusring {
	border: 0;
	outline: 0
}

.scrollb::-webkit-scrollbar {
	width: .5rem;
	height: .5rem
}

.scrollb::-webkit-scrollbar-thumb {
	background-color: #a9a9a9
}

.scrollb::-webkit-scrollbar-track {
	background-color: transparent
}

.scrollb::-webkit-scrollbar-corner {
	background-color: transparent
}

.scrollb{
  overflow-y: auto;
  margin-top: 0;
  width:257px;
  height: calc(100% - (3.5rem + 1px));
	overflow-x: none;
	/*overflow-y: initial;*/
	padding-bottom: 0;
	padding-left: .4rem;
	padding-right: .5rem;
	padding-top: 0;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #a9a9a9 transparent
  
}

.estadistica {
  background-color: #71c5f3;
  color:#000000 !important;
  border-radius: 5px 5px 5px 5px;
 -moz-border-radius: 5px 5px 5px 5px;
 -webkit-border-radius: 5px 5px 5px 5px;
 border: 0px solid #000000;
 }
 
 .administradores {
   background-color: #ffb54f !important;
   color:#000000 !important;
   border-radius: 5px 5px 5px 5px;
   -moz-border-radius: 5px 5px 5px 5px;
   -webkit-border-radius: 5px 5px 5px 5px;
    border: 0px solid #000000;
  }
 
 .ubicacion{
   background-color: #ece9e9 !important;
   color:#000000 !important;
   border-radius: 5px 5px 5px 5px;
   -moz-border-radius: 5px 5px 5px 5px;
   -webkit-border-radius: 5px 5px 5px 5px;
   border: 0px solid #000000;
 }
 
 .deportes{
   background-color: #fad68e !important; 
   color:#000000 !important;
   border-radius: 5px 5px 5px 5px;
   -moz-border-radius: 5px 5px 5px 5px;
   -webkit-border-radius: 5px 5px 5px 5px;
   border: 0px solid #000000;
 }

 .modulos{
  background-color: #fab98e !important; 
  color:#000000 !important;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 0px solid #000000;
}

.empresas {
  background-color: #71c5f3 !important;
  color:#000000 !important;
  border-radius: 5px 5px 5px 5px;
 -moz-border-radius: 5px 5px 5px 5px;
 -webkit-border-radius: 5px 5px 5px 5px;
 border: 0px solid #000000;
 }
 
 .modal{
  
 }
 



